












































































import Vue from 'vue';

export default Vue.extend({
  name: 'other-company-data',
  props: {
    data: {
      type: Array,
      required: true,
    },
    priceFormData: {
      type: Object,
      required: true,
    },
  },
});
