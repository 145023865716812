























































































































import Vue from 'vue';

export default Vue.extend({
  name: 'price-info',
  props: {
    data: {
      type: Object,
      required: true,
    },
    priceFormData: {
      type: Object,
      required: true,
    },
    priceAPIData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLogistikInsurance(): boolean {
      return this.data.insurance_type === 'LOGISTICS_INSURANCE';
    },
    showPaymentAddres(): boolean {
      return this.data.form_data.customerData.general.paymentStreet || this.data.form_data.customerData.general.paymentNR ||
        this.data.form_data.customerData.general.paymentPLZ || this.data.form_data.customerData.general.paymentOrt || this.data.form_data.customerData.general.paymentLand;
    },
    showBankInfo(): boolean {
      return this.data.form_data.customerData.general.accountOwner || this.data.form_data.customerData.general.iban1 || this.data.form_data.customerData.general.iban2 ||
        this.data.form_data.customerData.general.iban3 || this.data.form_data.customerData.general.iban4 || this.data.form_data.customerData.general.iban5 || this.data.form_data.customerData.general.iban6 ||
        this.data.form_data.customerData.general.swiftCode || this.data.form_data.customerData.general.bandName;
    },
    priceAfterSplit(): string {
      if (this.priceFormData.isSecondSelected !== undefined && this.priceFormData.isSecondSelected) {
        return this.priceFormData.secondPriceAfterSplit;
      }
      return this.priceFormData.priceAfterSplit;
    },
  },
});
