







































import Vue from 'vue';

export default Vue.extend({
  name: 'sample-collection-data',
  props: {
    data: {
      type: Object,
      required: true,
    },
    barText: {
      type: String,
      required: true,
    },
  },
});
