



























import Vue from 'vue';
import { KlauselnArray } from '@/constant/klauselnArray';

export default Vue.extend({
  name: 'fairground-insurance',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTitle(Klauseln: any) {
      const result: string[] = [];
      let index;
      Klauseln.forEach((item: any) => {
        index = KlauselnArray.findIndex(((i) => i.value === item));
        if (index !== -1) {
          result.push(KlauselnArray[index].title);
        }
      });
      return result;
    },
  },
});
