
















































































import Vue from 'vue';

export default Vue.extend({
  name: 'customer-data-fields',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
});
