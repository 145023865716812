





































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'agent-info',
  props: {
    data: {
      type: Object,
      required: true,
    },
    barText: {
      type: String,
      required: true,
    },
    barGruppe: {
      type: String,
      required: true,
    },
  },
});
