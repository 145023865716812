<template>
  <div>
    <div class="custom__collapse" :class="{ active: isCollapseActive }">
      <div class="collapse__header" @click="isCollapseActive = !isCollapseActive">
        <h3 class="pb-0 mb-0 title blue__label font-18">Zusammenfassung</h3>
      </div>
      <div class="collapse__body">
        <insurance-data :data="response" />
      </div>
    </div>
    <div class="row justify-content-end mt-4 mr-2">
        <div v-if="isCTCEmailSent">
            <p class="blue__label">CTC E-Mail versendet: {{ ctcEmailSent | formatDate }}</p>
            <p class="blue__label">CTC E-Mail gültig bis: {{ ctcEmailExpiration | formatDate}}</p>
            <p class="blue__label">CTC E-Mail bestätigt am: <span v-if="ctcEmailCompletedAt != ''">{{ ctcEmailCompletedAt | formatDate }}</span></p>
          </div>
      </div>
    <div class="my-4">
      <!-- <p class="transport__label">
        „Ich bestätige, dass die Unterschriften des Kunden zum Antrag und zum SEPA Mandat/Bankeinzug vorliegen und werde die unterschriebenen Papiere unverzüglich an Allianz Esa übermitteln“
      </p> -->
      <div class="d-flex justify-content-end py-4 proposal-policy-buttons">
<!--        <button class="btn btn-primary"-->
<!--                type="button"-->
<!--                @click="onGeneratePdfClick"-->
<!--                :disabled="!this.id">Vorschlag SEPA</button>-->
        <a :href="WEBSITE_PUBLIC_URL + 'files/pdf/' + pdf" target="_blank" class="btn btn-primary" :class="{disabled: pdf === ''}">Zusammenfassung als PDF speichern</a>
        <button :disabled="isPolicyComplete || statusForPolice" class="btn btn-primary" :class="{disabled: pdf === ''}" @click="onRequestProposalClick">
          Vorschlag anfordern
        </button>
        <button :disabled="DisabledPoliceAbschlieben || statusForPolice" @click="policyOpen" class="btn btn-primary" :class="{disabled: pdf === ''}">
          Police abschließen
        </button>
        <button class="btn btn-primary" @click="onPolicyComplete">Speichern und schließen</button>
      </div>
      <div class="custom__alert" :class="{ active: isStatusForPolice}">
        Die Police wurde bereits abgeschlossen/versendet. Ein erneuter Abschluss/Versand ist nicht möglich
      </div>
      <div class="custom__alert" :class="{ active: customerData.country !== 'Deutschland' }">
        Firmensitz außerhalb Deutschland. Weitere Prüfung erforderlich
      </div>
    </div>
    <form @submit.prevent="onProposalEmail">
      <modal :isOpen="isEmailOpen" @close="isEmailOpen = !isEmailOpen">
        <p class="blue__label font-22 text-left">An welche Adresse soll der Vorschlag gesendet werden?</p>
        <label class="text-center blue__label" v-if="userIsAzam">Makler E-Mail</label>
        <label class="text-center blue__label" v-else>Original Empfänger</label>
        <input :required="!userIsAzam" class="form-control" v-model="proposalEmail" placeholder="E-mail Adresse eintragen" type="email" @input="proposalEmailCheckShow = true" @copy.prevent/>
        <div v-if="proposalEmailCheckShow" class="mt-2">
          <input :required="!userIsAzam" :class="{ 'is-valid': validProposalSend , 'is-invalid': !validProposalSend }" class="form-control" v-model="proposalEmailCheck" placeholder="E-Mail wiederholen:" type="email"/>
        </div>
        <label class="mt-2 text-center blue__label" v-if="userIsAzam">Maklerbetreuer E-Mail</label>
        <label class="mt-2 text-center blue__label" v-else>CC E-Mail (optional)</label>
        <input :required="userIsAzam && proposalEmailCheck.length == 0" v-model="CCEmail" class="form-control" placeholder="CC E-Mail (optional)" type="email">

        <lable class="col-md-10 blue__label text-sm text-left" style="padding-left: 0px;">
          Der Kunde ist mit dem Versand an die oben stehende E-Mail-Adresse einverstanden.<br> Die Kommunikationsdaten wurden nochmals überprüft.
        </lable>
        <input type="checkbox" class="form-control col-md-1 form-small" :checkedProposal="checkedProposal" @click="checkConfirmProposal()"/>
        <error-message :body="`Die E-Mail Adressen stimmen nicht überein.`"
                       v-if="!validProposalSend"></error-message>
        <template #footer>
          <button class="btn btn-primary" :disabled="!validProposalSend || !checkedProposal">Weiter</button>
        </template>
      </modal>
    </form>

    <modal :isLarge="true" :isOpen="isPolicyOpen" @close="isPolicyOpen = !isPolicyOpen">
      <template #header>
        <h5 class="modal-title blue__label font-18">Versicherung beantragen</h5>
      </template>

      <template>
        <p class="transport__label">
          Hiermit beantrage ich den Abschluss der Versicherung zur Versicherungsvorschlag-Nummer {{ idWithYear }}.
          Der Versicherungsschutz beginnt zu dem vereinbarten Zeitpunkt nach Maßgabe der Versicherungsbedingungen.
          Mit diesem Beginn des Versicherungsschutzes bin ich einverstanden, auch wenn er vor Ablauf der Widerrufsfrist liegt.
          Mit der Unterschrift/Bestätigung erteile ich auch das beiliegende SEPA-Mandat.
          Die vorvertraglichen Informationen habe ich erhalten. Ich bestätige die Vollständigkeit und Richtigkeit meiner Angaben.
        </p>
        <p class="transport__label">
          Die für den Abschluss der Versicherung erforderlichen Angaben wurden in der Risikoerfassung gemacht.
        </p>
        <p class="transport__label">
          Die Erklärungen zur Datenverarbeitung, Hinweise zu den Vertragsgrundlagen und Widerrufsrecht sowie sonstige Versicherungsinformationen sind dem Vorschlag bzw. der Police zu entnehmen und stehen als Download auf der Homepage
          <a href="https://www.allianz-esa.de/">www.allianz-esa.de</a>
          zur Verfügung.
        </p>
        <hr />
        <div class="row">
          <div class="col-12 col-sm-7">
            <h5 class="blue__label">
              Bitte erfassen Sie eine E-Mail-Adresse.
            </h5>
            <p>
              An diese E-Mail-Adresse verschicken wir die „Zusammenfassung der Angaben (Vorschlag) inklusive dem Preis“, die „Versicherungsbedingungen“, das „Produkt-Informations-Blatt“ sowie die „Erklärungen und Hinweise zum Antrag auf Abschluss einer Versicherung“.
            </p>
          </div>
          <div class="col-12 col-sm-5 text-right">
            <label class="blue__label float-left" v-if="userIsAzam">Makler E-Mail</label>
            <label class="blue__label float-left" v-else>Original Empfänger</label>
            <input class="form-control" placeholder="E-mail Adresse eintragen" type="email" v-model="proposalEmail" @input="proposalEmailCheckShow = true" @copy.prevent />
            <div v-if="proposalEmailCheckShow" class="mt-2">
              <input required :class="{ 'is-valid': validProposalSend , 'is-invalid': !validProposalSend }" class="form-control" v-model="proposalEmailCheck" placeholder="E-Mail wiederholen:" type="email"/>
            </div>
            <label class="mt-2 blue__label float-left" v-if="userIsAzam">Maklerbetreuer E-Mail</label>
            <label class="mt-2 blue__label float-left" v-else>CC E-Mail (optional)</label>
            <input v-model="CCEmail" class="form-control" placeholder="CC E-Mail (optional)" type="email">
            <div class="row mt-2 align-items-center">
              <lable class="col-md-10 blue__label text-sm text-left">
                Der Kunde ist mit dem Versand an die oben stehende E-Mail-Adresse einverstanden. Die Kommunikationsdaten wurden nochmals überprüft.
              </lable>
              <input type="checkbox" class="form-control col-md-1 form-small" :checked="checked" @click="checkConfirm()"/>
            </div>
            <button @click="onProposalEmailModal" class="btn btn-primary mt-3" :disabled="!checked">
              Vorschlag per E-Mail
            </button>
          </div>
          <div class="col-12">
            <error-message :body="`Die E-Mail Adressen stimmen nicht überein.`"
                           v-if="!validProposalSend"></error-message>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="row align-items-center">
          <div class="col-12 col-sm">
            <h5 class="blue__label">Vertragsschlussverfahren</h5>
            <p>Der Versicherungsvertrag kommt zustande, wenn der Antrag des Kunden vom Versicherer angenommen wird.
              Der Versicherer erklärt seine Annahme, in dem er dem Kunden den Versicherungsschein zusendet.
              Möglichkeiten des Kunden, seinen Antrag zu stellen, durch:</p>
          </div>
          <div class="col-12 col-sm d-flex justify-content-center align-items-end flex-column">
            <form-switch :defaultValue="true"
                         name="customerConfirmationClick"
                         loaderShow="loaderShowConfirmation"
                         v-model="customerConfirmationClick"
                         @input="setActive('customerConfirmationClick')"
            >
              <template #label>
                <span class="col-form-label">
                  Click2Contract Bestätigungsklick durch Kunden
                </span>
              </template>
            </form-switch>
            <form-switch :defaultValue="true"
                         name="digitalSignature"
                         loaderShow="loaderShowDigital"
                         v-model="digitalSignature"
                         @input="setActive('digitalSignature')"
            >
              <template #label>
                <span class="col-form-label">
                  Unterschrift digital
                </span>
              </template>
            </form-switch>

            <form-switch :defaultValue="true"
                         name="paperSignature"
                         loaderShow="loaderShowPaper"
                         v-model="paperSignature"
                         @input="setActive('paperSignature')"
                         :disabled="paymentMethodIsLastschrift"
            >
              <template #label>
                <span class="col-form-label" :class="{ 'disabled': paymentMethodIsLastschrift }">
                  Unterschrift auf Papier
                </span>
              </template>
            </form-switch>
            <button :disabled="!isCTCEmailSent && (!hasEmailSent || statusForPolice) || !checked" @click="anKundenUebergeben" class="btn btn-lg btn-success rounded-0">
              <div v-if="loaderShow" class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              An Kunden übergeben
            </button>
            <div v-if="isCTCEmailSent">
              <p class="blue__label">CTC E-Mail versendet: {{ ctcEmailSent | formatDate }}</p>
              <p class="blue__label">CTC E-Mail gültig bis: {{ ctcEmailExpiration | formatDate}}</p>
              <p class="blue__label">CTC E-Mail bestätigt am: <span v-if="ctcEmailCompletedAt != ''">{{ ctcEmailCompletedAt | formatDate }}</span></p>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <modal :isOpen="isQrCodeOpen" @close="isQrCodeOpen = !isQrCodeOpen">
      <template #header>
        <h5 class="modal-title blue__label">
          Bitte scannen Sie den QR-Code mit der Kamera Ihres Handys.
        </h5>
      </template>
      <div class="row">
        <div class="col-12">
          <p class="transport__label">Sie können dann die Unterschrift auf Ihrem Handy leisten.</p>
        </div>
        <div class="col-12 text-center">
          <qrcode v-if="isQrCodeOpen" :options="{ width: 300, height: 300 }" :value="qrValue" />
        </div>

        <div class="col-12 text-center pt-3">
          Versenden des QR-Code per E-mail.
          <input class="form-control" placeholder="E-mail Adresse eintragen" type="email" v-model="proposalEmail" />
          <button @click="sendDigitalSignEmail" class="btn btn-primary mt-3">
            QR-Code senden
          </button>
        </div>
      </div>

      <template #footer>
        <button @click="isQrCodeOpen = !isQrCodeOpen" class="btn btn-danger">Schließen</button>
      </template>
    </modal>

    <modal :isOpen="isMsgOpen" @close="isMsgOpen = !isMsgOpen">
      <template #header>
        <h5 class="modal-title blue__label">
          Vielen Dank für den Abschluss der Transport Versicherung.
        </h5>
      </template>
      <img :src="signature" class="signature__img" />
      <p class="transport__label">
        Den Preis sowie alle erfassten Risikoinformation erhalten Sie auch per Klick auf „Zusammenfassung als PDF speichern“. Sämtliche Daten und Informationen zu Ihrem Antrag stehen auch im Reiter „Bedingungen / Dateien“ zum Download bereit.
      </p>
      <p>
        Die Police werden wir zeitnah ausfertigen und Ihnen auf dem vereinbarten Zustellweg (Post, Mail, Portal) zur Verfügung stellen.
      </p>
      <template #footer>
        <button class="btn btn-primary" @click="isMsgOpen = !isMsgOpen">Fenster schließen</button>
      </template>
    </modal>
    <modal :isOpen="isConcludegOpen" @close="isConcludegOpen = !isConcludegOpen">
      <template #header>
        <h5 class="modal-title blue__label">
          Vielen Dank für den Abschluss der Transport Versicherung.
        </h5>
      </template>
      <p class="transport__label">
        Den Preis sowie alle erfassten Risikoinformation erhalten Sie auch per Klick auf „Zusammenfassung als PDF speichern“. Sämtliche Daten und Informationen zu Ihrem Antrag stehen auch im Reiter „Bedingungen / Dateien“ zum Download bereit.
      </p>
      <p>
        Die Police werden wir zeitnah ausfertigen und Ihnen auf dem vereinbarten Zustellweg (Post, Mail, Portal) zur Verfügung stellen.
      </p>
      <template #footer>
        <button class="btn btn-primary" @click="isConcludegOpen = !isConcludegOpen">Fenster schließen</button>
      </template>
    </modal>
    <modal :isOpen="isOpenFeedbackPopup" @close="isOpenFeedbackPopup = !isOpenFeedbackPopup">
      <template #header>
        <h5 class="modal-title blue__label">
          Der Vorschlag zur Versicherung wurde soeben per E-Mail zugestellt.
        </h5>
      </template>
      <p class="transport__label">
        Den Preis sowie alle erfassten Risikoinformationen erhalten Sie auch per Klick auf “Zusammenfassung als PDF speichern”.
      </p>
      <p>
        Die Police können Sie direkt per Klick auf “Police abschließen” beantragen. Dies kann auch direkt mit einer digitalen Unterschrift erfolgen.
      </p>
      <template #footer>
        <button class="btn btn-primary" @click="isOpenFeedbackPopup = !isOpenFeedbackPopup">Fenster schließen</button>
      </template>
    </modal>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import QrCode from '@chenfengyuan/vue-qrcode';
import Modal from '@/components/shared/modal.vue';
import InsuranceData from '@/components/insurance-data.vue';
import isMobile from '@/helper/is-mobile';
import priceCalculator from '@/service/price-calculator';
import pusher from '@/config/pusher';
import { WEBSITE_URL, WEBSITE_PUBLIC_URL, CASE_STATUS } from '@/constant/constants';
import errorMessage from '@/components/form/error-message.vue';
import FormSwitch from '@/components/form/form-switch.vue';

Vue.component(QrCode.name, QrCode);

export default Vue.extend({
  name: 'proposal-policy',
  components: {
    FormSwitch,
    Modal,
    InsuranceData,
    errorMessage,
  },
  watch: {
    isConcludegOpen(value) {
      if (!value) {
        this.isPolicyOpen = false;
      }
      return value;
    },
    isPolicyOpen(value) {
      if (!value) {
        this.hasEmailSent = false;
      }
      return value;
    },
    isMsgOpen(value) {
      if (!value) {
        this.isQrCodeOpen = false;
      }
      return value;
    },
  },
  props: ['response', 'customerData', 'customerDetails', 'caseStatus', 'id', 'isAPIObserver', 'sourceSystem', 'idWithYear'],
  data() {
    console.log(this.response, '---------');
    return {
      customerConfirmationClick: true,
      digitalSignature: false,
      paperSignature: false,
      loaderShow: false,
      isCollapseActive: false,
      isStatusForPolice: false,
      isEmailOpen: false,
      isPolicyOpen: false,
      hasEmailSent: false,
      isQrCodeOpen: false,
      isMobile: false,
      isMsgOpen: false,
      isConcludegOpen: false,
      isOpenFeedbackPopup: false,
      qrValue: '',
      proposalEmail: this.response.form_data.customerData.user.email ?? '',
      proposalEmailCheck: this.response.form_data.customerData.user.email ?? '',
      proposalEmailCheckShow: false,
      CCEmail: '',
      // statusIns: this.response.status,
      priceIsFilled: this.response.price,
      manuallyPrice: this.response.manually_price_data,
      pdf: '',
      signature: '',
      isPolicyComplete: false,
      emailSubject: 'Vorschlag - Allianz Esa',
      WEBSITE_PUBLIC_URL,
      isCTCEmailSent: false,
      ctcEmailSent: '',
      ctcEmailExpiration: '',
      ctcEmailCompletedAt: '',
      checked: false,
      checkedProposal: false,
    };
  },
  computed: {
    paymentMethodIsLastschrift() {
      return this.customerDetails.paymentMethod === 'Lastschrift';
    },
    emailAddres() {
      if (this.userIsAzam && this.proposalEmail === '') {
        return this.CCEmail;
      }
      return this.proposalEmail;
    },
    emailAddresCC() {
      if (this.userIsAzam && this.proposalEmail === '') {
        return '';
      }
      return this.CCEmail;
    },
    validProposalSend() {
      return (this.proposalEmailCheck === this.proposalEmail && ((this.proposalEmailCheck.length > 0 && this.proposalEmail.length) || this.userIsAzam));
    },
    priceComputed() {
      return !(this.response.form_data.price_form_data.noPrice || (this.priceIsFilled === 0 && this.manuallyPrice === null));
    },
    DisabledPoliceAbschlieben() {
      return (this.isPolicyComplete || this.customerData.country !== 'Deutschland' || (this.priceIsFilled === 0 && this.manuallyPrice === null));
    },
    statusForPolice() {
      console.log(!(this.caseStatus === CASE_STATUS.OFFER || this.caseStatus === CASE_STATUS.OFFER_REQUEST || this.caseStatus === CASE_STATUS.OFFER_REQUEST_READY || this.caseStatus === CASE_STATUS.SUGGESTION));
      return !(this.caseStatus === CASE_STATUS.OFFER || this.caseStatus === CASE_STATUS.OFFER_REQUEST || this.caseStatus === CASE_STATUS.OFFER_REQUEST_READY || this.caseStatus === CASE_STATUS.SUGGESTION);
    },
    userIsAzam() {
      return (this.sourceSystem.toUpperCase() === 'AZAM' || this.sourceSystem.toUpperCase() === 'AZMS');
    },
  },
  methods: {
    setActive(selected) {
      this.digitalSignature = selected === 'digitalSignature';
      this.paperSignature = selected === 'paperSignature';
      this.customerConfirmationClick = selected === 'customerConfirmationClick';
    },
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    policyOpen() {
      this.getStatusInsuranceFromBackend();
      if (!this.statusForPolice) {
        this.isPolicyOpen = true;
      } else {
        this.isStatusForPolice = true;
      }
    },
    async onGeneratePdfClick() {
      priceCalculator.generatePDF(this.id).then(() => {
        window.open(`${WEBSITE_URL}generate-pdf-direct-debit/${this.id}`);
      });
    },
    anKundenUebergeben() {
      if (this.digitalSignature) {
        this.handleDigitalSignature();
      } else if (this.paperSignature) {
        this.handlePaperSignature();
      } else if (this.customerConfirmationClick) {
        this.handleCustomerConfirmationClick();
      } else {
        alert('Bitte wählen Sie eine Option.');
      }
    },
    handleDigitalSignature() {
      this.isPolicyOpen = false;
      this.isQrCodeOpen = true;
    },
    handlePaperSignature() {
      this.isPolicyComplete = true;
      this.isConcludegOpen = true;
      this.hasEmailSent = true;
      this.$emit('case-status-change', CASE_STATUS.CONTRACT_NO_SIGNATURE); // set status
      priceCalculator.sendProposalEmail({
        email: this.emailAddres,
        emailCC: this.emailAddresCC,
        id: this.response.id,
        pdf: this.pdf,
        btn: 'police-vorschlag',
        status: CASE_STATUS.CONTRACT_NO_SIGNATURE,
      }).then((response) => {
        if (response.data.error !== undefined) {
          this.$emit('case-status-change', response.data.status); // set status
        }
      });
    },
    handleCustomerConfirmationClick() {
      this.loaderShow = true;
      priceCalculator.sendPolicyProposalEmail({
        email: this.emailAddres,
        emailCC: this.emailAddresCC,
        id: this.response.id,
      }).then((response) => {
        if (response.data.error !== undefined) {
          this.$emit('case-status-change', response.data.status); // set status
        }
        const { ctcEmailSent, ctcEmailExpiration } = response.data;
        this.hasEmailSent = true;
        this.loaderShow = false;
        this.isCTCEmailSent = true;
        this.ctcEmailSent = new Date(ctcEmailSent);
        this.ctcEmailExpiration = new Date(ctcEmailExpiration);
        this.ctcEmailCompletedAt = '';
      });
    },
    onRequestProposalClick() {
      this.getStatusInsuranceFromBackend();
      if (!this.statusForPolice) {
        this.isEmailOpen = true;
      } else {
        this.isStatusForPolice = true;
      }
    },
    onProposalEmail() {
      if (!this.priceComputed) {
        // priceCalculator.sendEmailToAdmin({
        //  id: this.response.id,
        // });
        this.$emit('onOfferRequest');
      }
      priceCalculator.sendProposalEmail({
        email: this.emailAddres,
        emailCC: this.emailAddresCC,
        id: this.response.id,
        pdf: this.pdf,
        btn: 'vorschlag',
      })
        .finally(() => {
          this.isEmailOpen = false;
          this.isOpenFeedbackPopup = true;
        });
    },
    onProposalEmailModal() {
      this.loaderShow = true;
      this.$emit('case-status-change', CASE_STATUS.SUGGESTION); // set status
      priceCalculator.sendProposalEmail({
        email: this.emailAddres,
        emailCC: this.emailAddresCC,
        id: this.response.id,
        pdf: this.pdf,
        btn: 'beantragen-vorschlag',
      }).finally(() => {
        this.hasEmailSent = true;
        this.loaderShow = false;
      });
    },
    sendDigitalSignEmail() {
      priceCalculator.emailDigital({
        email: this.emailAddres,
        emailCC: this.emailAddresCC,
        link: this.qrValue,
        id: this.response.id,
      });
    },
    onPolicyComplete() {
      this.isPolicyComplete = true;
      this.$router.push('/products');
      // router.push({ path: 'products' });
    },
    onGeneratePDF() {
      priceCalculator.generatePDFforUser(this.id).then((response) => {
        // console.log(response);
        const pdfName = response.data;
        this.pdf = pdfName;
      });
      this.$emit('onAddPdf', this.pdf);
    },
    getStatusInsuranceFromBackend() {
      priceCalculator.getStatusInsurance(this.id).then((response) => {
        this.$emit('case-status-change', response.data);
        return (response.data === CASE_STATUS.OFFER || response.data === CASE_STATUS.OFFER_REQUEST || response.data === CASE_STATUS.OFFER_REQUEST_READY || response.data === CASE_STATUS.SUGGESTION);
      });
    },
    checkConfirm() {
      this.checked = !this.checked;
    },
    checkConfirmProposal() {
      this.checkedProposal = !this.checkedProposal;
    },
  },
  // beforeMount() {
  //   this.onGeneratePDF();
  // },
  beforeDestroy() {
    pusher.unbind('userSigned');
  },
  mounted() {
    this.$emit('get-status-insurance', this.id);
    this.onGeneratePDF();
    this.getStatusInsuranceFromBackend();

    if (this.response && this.response?.ctc_token !== null) {
      this.isCTCEmailSent = true;
      this.ctcEmailSent = new Date(this.response.ctc_token.ctc_email_sent);
      this.ctcEmailExpiration = new Date(this.response.ctc_token.ctc_email_expire);

      if (this.response.policy_completed_at !== null) this.ctcEmailCompletedAt = new Date(this.response.policy_completed_at);
    }

    this.isMobile = isMobile();
    this.qrValue = `${window.location.origin}/signature-pad/${this.response.id}`;

    pusher
      .subscribe(`sign-channel.${localStorage.getItem('id')}`)
      .bind('userSigned', (response) => {
        this.signature = response.signImage;
        console.log(response.connectAMSstatus);
        this.isMsgOpen = true;
        this.$emit('case-status-change', CASE_STATUS.CONTRACT_NO_SIGNATURE); // set status
        this.isPolicyComplete = true;
        this.$emit('on-digital-sign');
      });
  },
});
</script>

<style lang="scss" scoped>
  .disabled{
    opacity: 0.5;
  }
  .signature__img {
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 300px;
    object-position: center;
    object-fit: contain;
  }
  .disabled{
    opacity: 0.5;
  }

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 16px;
  }
  .lds-spinner div {
    transform-origin: 10px 10px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 15px;
    left: 9px;
    width: 2px;
    height: 6px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  .text-sm {
    font-size: 12px;
  }
  .form-small {
    width: 15px;
    height: 15px;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

</style>
