

































import Vue from 'vue';
import { getProductNameForPricePageHelper } from '@/helper/functions';
import CustomerData from './insurance/customer-data.vue';
import AgentInfo from './insurance/agent-info.vue';
import AnnualInsurance from './insurance/annual-insurance.vue';
import SampleCollection from './insurance/sample-collection.vue';
import ShortTerm from './insurance/short-term.vue';
import CustomerDetails from './insurance/customer-details.vue';
import OwnGoods from './insurance/own-goods.vue';
import PriceInfo from './insurance/price-info.vue';
import OtherCompanyData from './insurance/other-company-data.vue';
import AssesmentInfo from './insurance/assesment-info.vue';
import FairgroundInsurance from './insurance/fairground-insurance.vue';
import luggageInsurance from './insurance/luggage-insurance.vue';
import MarketConceptInsurance from './insurance/market-concept-insurance.vue';
import ValorInsuranceCompact from './insurance/valor-insurance-compact.vue';
import logisticsInsurance from './insurance/logistics-insurance.vue';

export default Vue.extend({
  name: 'insurance-data',
  components: {
    CustomerData,
    AgentInfo,
    AnnualInsurance,
    SampleCollection,
    ShortTerm,
    OwnGoods,
    CustomerDetails,
    PriceInfo,
    OtherCompanyData,
    AssesmentInfo,
    FairgroundInsurance,
    luggageInsurance,
    MarketConceptInsurance,
    ValorInsuranceCompact,
    logisticsInsurance,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      key: this.data.insurance_type,
      user: this.data.form_data.customerData.user,
      details: this.data.form_data.customerData.general,
      assesment: this.data.form_data.assesment,
      formData: this.data.form_data,
      insuranceData: this.data.form_data.insuranceData,
      companyData: this.data.form_data.customerData.user.otherCompaniesData,
      priceData: this.data.price_data.Result || {
        status: 'nonOK',
      },
    };
  },
  computed: {
    getProductName(): string {
      if (this.key === 'FORWARDING_AGENT_INSURANCE') {
        if (this.data.form_data.price_form_data.isSecondSelected !== undefined) {
          return getProductNameForPricePageHelper(this.key, this.data.form_data.price_form_data.isSecondSelected);
        }
      }
      // console.log(this.data.form_data.assesment.operatingMode['BART-Gruppe']);
      return getProductNameForPricePageHelper(this.key);
    },
  },
});
