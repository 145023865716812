





































































import Vue from 'vue';

export default Vue.extend({
  name: 'luggage-insurance',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
});
