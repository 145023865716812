





























import Vue from 'vue';

export default Vue.extend({
  name: 'modal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isOpen() {
      document.body.classList.toggle('modal-open');
    },
  },
});
