














































































import Vue from 'vue';

export default Vue.extend({
  name: 'customer-details',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    noOfDamagesStr(): string {
      switch (this.data.noOfDamages) {
        case '0':
          return '0';
        case '5':
          return '>bis 5';
        case '10':
          return '>bis 10';
        case '25':
          return '>bis 25';
        case '50':
          return '>bis 50';
        case '51':
          return '>über 50';
        default:
          return '0';
      }
    },
  },
});
